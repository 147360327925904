var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"ConnectV2Input",style:({ width: _vm.wrapperWidth })},[(!_vm.hideLabel)?_c('label',{attrs:{"for":_vm.componentId}},[_vm._t("label")],2):_vm._e(),_vm._v(" "),(_vm.hint)?_c('p',{class:[_vm.s.hint, ( _obj = {}, _obj[_vm.s.hiddenHint] = _vm.hiddenHint, _obj )]},[_vm._v("\n    "+_vm._s(_vm.hint)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{class:_vm.s.inner,style:({ width: _vm.width })},[((_vm.inputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"connectV2FormItem",class:[
        _vm.s.input,
        ( _obj$1 = {}, _obj$1[_vm.s.inputError] = _vm.hasError || _vm.hasErrorInternal, _obj$1[_vm.s.preview] = _vm.isPreview, _obj$1 ) ],style:(_vm.style),attrs:{"id":_vm.componentId,"disabled":_vm.disabled,"name":_vm.name,"required":_vm.required,"placeholder":_vm.placeholder,"aria-describedby":_vm.ariaDescribedby,"data-group-id":_vm.groupId,"max":_vm.max,"min":_vm.min,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.model)?_vm._i(_vm.model,null)>-1:(_vm.model)},on:{"blur":_vm.onBlur,"change":[function($event){var $$a=_vm.model,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.model=$$a.concat([$$v]))}else{$$i>-1&&(_vm.model=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.model=$$c}},_vm.onChange],"focus":_vm.onFocus,"input":_vm.onInput,"wheel":function (e) { return e.target.blur(); }}}):((_vm.inputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"connectV2FormItem",class:[
        _vm.s.input,
        ( _obj$2 = {}, _obj$2[_vm.s.inputError] = _vm.hasError || _vm.hasErrorInternal, _obj$2[_vm.s.preview] = _vm.isPreview, _obj$2 ) ],style:(_vm.style),attrs:{"id":_vm.componentId,"disabled":_vm.disabled,"name":_vm.name,"required":_vm.required,"placeholder":_vm.placeholder,"aria-describedby":_vm.ariaDescribedby,"data-group-id":_vm.groupId,"max":_vm.max,"min":_vm.min,"type":"radio"},domProps:{"checked":_vm._q(_vm.model,null)},on:{"blur":_vm.onBlur,"change":[function($event){_vm.model=null},_vm.onChange],"focus":_vm.onFocus,"input":_vm.onInput,"wheel":function (e) { return e.target.blur(); }}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model),expression:"model"}],staticClass:"connectV2FormItem",class:[
        _vm.s.input,
        ( _obj$3 = {}, _obj$3[_vm.s.inputError] = _vm.hasError || _vm.hasErrorInternal, _obj$3[_vm.s.preview] = _vm.isPreview, _obj$3 ) ],style:(_vm.style),attrs:{"id":_vm.componentId,"disabled":_vm.disabled,"name":_vm.name,"required":_vm.required,"placeholder":_vm.placeholder,"aria-describedby":_vm.ariaDescribedby,"data-group-id":_vm.groupId,"max":_vm.max,"min":_vm.min,"type":_vm.inputType},domProps:{"value":(_vm.model)},on:{"blur":_vm.onBlur,"change":_vm.onChange,"focus":_vm.onFocus,"input":[function($event){if($event.target.composing){ return; }_vm.model=$event.target.value},_vm.onInput],"wheel":function (e) { return e.target.blur(); }}}),_vm._v(" "),(_vm.icon)?_c('font-awesome-icon',{class:_vm.s.icon,attrs:{"icon":_vm.icon}}):_vm._e()],1),_vm._v(" "),(_vm.error && _vm.errorText)?_c('p',{class:_vm.s.error,attrs:{"id":_vm.errorId,"aria-live":"assertive"}},[_vm._v("\n    "+_vm._s(_vm.errorText)+"\n  ")]):(_vm.errorInternal && _vm.errorTextInternal)?_c('p',{class:_vm.s.error,attrs:{"id":_vm.errorId,"aria-live":"assertive"}},[_vm._v("\n    "+_vm._s(_vm.errorTextInternal)+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.maxlength)?_c('p',{class:[_vm.s.characterCount, ( _obj$4 = {}, _obj$4[_vm.s.error] = _vm.isExceeded, _obj$4 )]},[_vm._v("\n    "+_vm._s(_vm.characterCount)+" / "+_vm._s(_vm.maxlength)+"文字\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }