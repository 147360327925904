import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import IConnectPartnerInquiry from '../Interfaces/ConnectPartnerInquiry/IConnectPartnerInquiry'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectPartnerInquiryDefine',
})
export default class ConnectPartnerInquiryDefine extends VuexModule {
  connectPartnerInquiry: IConnectPartnerInquiry | undefined = undefined

  @Mutation
  set(connectPartnerInquiry: IConnectPartnerInquiry) {
    this.connectPartnerInquiry = connectPartnerInquiry
  }

  get get(): IConnectPartnerInquiry | undefined {
    return this.connectPartnerInquiry
  }
}
