






































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'

type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'gray'
  | 'clientPrimary'
  | 'transparent'
  | 'primary_ver2'

@Component
export default class ConnectButton extends Vue {
  @Prop({ default: 'button' })
  private type!: 'button' | 'submit' | 'reset'

  @Prop()
  private disabled!: boolean

  @Prop()
  private icon!: string

  @Prop()
  private size!: 'md' | 'sm' | 'large' | 'small' | 'medium'

  @Prop()
  private buttonColor!: ButtonColor

  @Prop()
  private width!: string

  @Prop({ default: undefined })
  private height!: string

  @Prop({ default: false })
  private isPreview!: boolean

  get buttonSize() {
    if (!this.size) {
      return 'md'
    }
    return this.size
  }

  get style() {
    if (!this.width && !this.height) {
      return ''
    }
    return `width: ${this.width}; height: ${this.height}`
  }

  @Emit('click')
  private onClick() {}
}
