

























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IPartnerSideMenuLink } from '~/Interfaces'
import { PartnerSideMenuLink } from '~/components/common/profile/partner/PartnerSideMenu/components'
import { ConnectButton } from '~/components/common/components'
import ConnectCompanyProfileValidator from '~/components/Profile/ConnectCompany/ConnectCompanyProfileValidator'
import { ConnectCompanyProfileValidatorStore } from '~/utils/store-accessor'

@Component({
  components: {
    ConnectButton,
    PartnerSideMenuLink,
  },
})
export default class PartnerSideMenu extends Vue {
  @Prop({ default: 'プロフィール' })
  private title!: string

  @Prop({ default: true })
  private isProfile!: boolean

  async mounted() {
    await this.$services.connectCompanyProfileValidator.set()
  }

  get validator(): ConnectCompanyProfileValidator | undefined {
    return ConnectCompanyProfileValidatorStore.get
  }

  get menuLinks() {
    if (this.isProfile) {
      return this.profileMenuLinks
    } else {
      return this.settingMenuLinks
    }
  }

  get profileMenuLinks(): IPartnerSideMenuLink[] {
    return [
      {
        label: '基本情報',
        to: '/company/profile/connect_company/info',
        complete: this.validator?.validateInfo,
      },
      {
        label: '特徴',
        to: '/company/profile/connect_company/features',
        complete: this.validator?.validateFeature,
      },
      {
        label: '支援実績',
        to: '/company/profile/connect_company/support_achievements',
        complete: this.validator?.validateSupportAchievements,
      },
      {
        label: '支援内容',
        to: '/company/profile/connect_company/support',
        complete: this.validator?.validateSupport,
      },
      {
        label: '支援体制',
        to: '/company/profile/connect_company/support_system',
        complete: this.validator?.validateSupportSystem,
      },
      {
        label: '代表者',
        to: '/company/profile/connect_company/representative',
        complete: this.validator?.validateRepresentative,
      },
      {
        label: '主要メンバー',
        to: '/company/profile/connect_company/members',
      },
      {
        label: '取組方針',
        to: '/company/profile/connect_company/policy',
      },
      {
        label: '料金目安',
        to: '/company/profile/connect_company/price',
      },
    ]
  }

  get settingMenuLinks(): IPartnerSideMenuLink[] {
    return [
      {
        label: '問い合わせフォーム',
        to: '/company/setting/partner_inquiry_form',
        hidden: !this.isPrime,
      },
    ]
  }

  get isPrime(): boolean {
    return (this.$auth.user?.is_prime as undefined | boolean) ?? false
  }

  private preview() {
    window.open('/company/profile/connect_company/new_preview', '_blank')
  }
}
