// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_3O4Gm{display:inline-block;background:#fff;padding:40px 10px 20px;min-height:100vh;height:100%}.inner_2TfaT{padding:0 20px 30px;display:flex;flex-direction:column;grid-gap:10px;gap:10px}.title_L5WXU{font-weight:600;font-size:16px;line-height:130%;color:#2c3846;padding:0}.list_3Gh5J{padding:0;list-style:none}", ""]);
// Exports
exports.locals = {
	"container": "container_3O4Gm",
	"inner": "inner_2TfaT",
	"title": "title_L5WXU",
	"list": "list_3Gh5J"
};
module.exports = exports;
