// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layout__lmkU{display:grid;grid-template-areas:\"header header\" \"menu content\";grid-template-columns:200px 1fr;grid-template-rows:auto auto}.header_2AcSl{grid-area:header}.menu_3tMw2{grid-area:menu}.main_KGl_K{position:relative;grid-area:content;height:100%;padding:50px 20px 200px}.main_KGl_K.max_width_840px_oUt6Q{max-width:840px}.main_KGl_K.padding_bottom_200px_te1wu{padding-bottom:200px}", ""]);
// Exports
exports.locals = {
	"layout": "layout__lmkU",
	"header": "header_2AcSl",
	"menu": "menu_3tMw2",
	"main": "main_KGl_K",
	"max_width_840px": "max_width_840px_oUt6Q",
	"padding_bottom_200px": "padding_bottom_200px_te1wu"
};
module.exports = exports;
