





































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import PartnerSideMenu from '../PartnerSideMenu/index.vue'
import PartnerBanner from './components/PartnerBanner/index.vue'
import ConnectSnackbar from '~/components/common/components/ConnectSnackbar.vue'
import ConnectConfirmModal from '~/components/common/components/ConnectConfirmModal.vue'
import { SnackbarStore } from '~/utils/store-accessor'
import ConnectV2Snackbar from '~/components/common/components/v2/ConnectV2Snackbar.vue'

@Component({
  components: {
    ConnectV2Snackbar,
    PartnerSideMenu,
    PartnerBanner,
    ConnectSnackbar,
    ConnectConfirmModal,
  },
})
export default class PartnerLayout extends Vue {
  @Prop({ default: false })
  private isMaxWidth840px!: boolean

  @Prop({ default: false })
  private idPaddingBottom200px!: boolean

  @Prop({ default: 'プロフィール' })
  private title!: string

  @Prop({ default: true })
  private isProfile!: boolean

  private onBack() {
    this.$router.back()
  }

  beforeDestroy() {
    SnackbarStore.hideAll()
  }
}
