// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".root_Ac0_l{pointer-events:none;position:fixed;z-index:1055;top:60px;right:60px}.root_Ac0_l .wrapper_2jVIl{text-align:center}.root_Ac0_l .snackBar_3bsJ2{position:relative;pointer-events:all;display:inline-block;background:#fff;border-radius:5px;box-shadow:1px 4px 12px 0 rgba(0,0,0,.25098);font-weight:600;font-size:14px;line-height:1.5;color:#454545;margin:0 auto;padding:10px;width:320px;min-height:50px}.root_Ac0_l .snackBar_3bsJ2 .close_3Lf4w{cursor:pointer;position:absolute;top:10px;right:10px;color:#969492;background:transparent;border:none;border-radius:5px;outline:none;padding:0;margin:0}.root_Ac0_l .snackBar_3bsJ2 .close_3Lf4w:hover{color:#3379b7;background:#e3eef7}.root_Ac0_l .snackBar_3bsJ2 .inner_Zr8Xh{display:flex;justify-content:flex-start;align-items:center;grid-gap:0 10px;gap:0 10px}.root_Ac0_l .snackBar_3bsJ2 .inner_Zr8Xh .icon_53TzJ{font-size:26px;color:#1ac08f}.root_Ac0_l .snackBar_3bsJ2 .inner_Zr8Xh .icon_53TzJ.error_1z6Gw{color:#e43a63}.root_Ac0_l .snackBar_3bsJ2 .hint_2G1Al{font-weight:300;font-size:13px;line-height:1.5;display:flex;justify-content:flex-start;align-items:center;text-align:left;color:grey;padding-top:5px;padding-left:40px;width:255px}.enterActive_1dcV4{-webkit-animation:slideIn_3_ab- .3s ease-out;animation:slideIn_3_ab- .3s ease-out}@-webkit-keyframes slideIn_3_ab-{0%{right:-320px;opacity:0}to{right:60px;opacity:1}}@keyframes slideIn_3_ab-{0%{right:-320px;opacity:0}to{right:60px;opacity:1}}.leaveActive_2NKGZ{-webkit-animation:slideOut_2ZW3f .3s ease-out;animation:slideOut_2ZW3f .3s ease-out}@-webkit-keyframes slideOut_2ZW3f{0%{right:60px;opacity:1}to{right:-320px;opacity:0}}@keyframes slideOut_2ZW3f{0%{right:60px;opacity:1}to{right:-320px;opacity:0}}", ""]);
// Exports
exports.locals = {
	"root": "root_Ac0_l",
	"wrapper": "wrapper_2jVIl",
	"snackBar": "snackBar_3bsJ2",
	"close": "close_3Lf4w",
	"inner": "inner_Zr8Xh",
	"icon": "icon_53TzJ",
	"error": "error_1z6Gw",
	"hint": "hint_2G1Al",
	"enterActive": "enterActive_1dcV4",
	"slideIn": "slideIn_3_ab-",
	"leaveActive": "leaveActive_2NKGZ",
	"slideOut": "slideOut_2ZW3f"
};
module.exports = exports;
