// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".menuLink_onR2Q{transition:all .1s ease-out;text-decoration:none;padding:7px 10px;display:flex;align-items:center;grid-gap:10px;gap:10px;width:180px;height:35px;background:#fff;border-radius:3px;font-size:14px;font-weight:700;color:#222}.menuLink_onR2Q:active,.menuLink_onR2Q:hover{background:#e3eef7;color:#3379b7}.menuLink_onR2Q .incomplete_31LzL{padding:1px 5px;display:flex;justify-content:center;align-items:center;grid-gap:10px;gap:10px;border-radius:2px;background:#eb6b8a;background:var(--Red-50,#eb6b8a);color:#fff;color:var(--Background-White,#fff);font-family:Hiragino Kaku Gothic Pro;font-size:12px;font-style:normal;font-weight:600;line-height:normal}", ""]);
// Exports
exports.locals = {
	"menuLink": "menuLink_onR2Q",
	"incomplete": "incomplete_31LzL"
};
module.exports = exports;
