import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectPartnerInquiryFormData from '~/Interfaces/ConnectPartnerInquiryForm/IConnectPartnerInquiryFormData'

export default class ConnectPartnerInquiryFormService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async isValid(primeId: string, url: string): Promise<boolean> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/partner_inquiry_form/is_valid/' + primeId,
        { url }
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getAgreementUrl(primeId: string): Promise<string | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get(
        'api/partner_inquiry_form/agreement_url/' + primeId
      )
      return response.data.url ?? ''
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async submit(
    primeId: string,
    data: IConnectPartnerInquiryFormData,
    url: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      await this.axios.post('api/partner_inquiry_form/submit/' + primeId, {
        data,
        fromUrl: url,
      })
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async updateUrls(urls: string[]): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      await this.axios.post(
        'api/company/setting/partner_inquiry_form/update_urls',
        {
          urls,
        }
      )
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getUrls(): Promise<string[]> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get(
        'api/company/setting/partner_inquiry_form/urls'
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return []
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
