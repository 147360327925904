// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wrapper_2Lvod{padding:0 0 10px;display:flex;flex-direction:column;grid-gap:4px;gap:4px}.inputLabel_hI5kD{display:flex;align-items:center;color:#454545;font-family:\"Hiragino Sans\";font-weight:700;font-size:14px;line-height:21px}.inputLabel_hI5kD.hidden_3mb87{visibility:hidden}.required_FP9h_{margin-right:8px;margin-bottom:1px;display:flex;align-items:center;justify-content:center;background:#d7e6f4;border-radius:2px;width:34px;height:20px;font-family:\"Hiragino Kaku Gothic Pro\";font-size:12px;font-weight:600;line-height:18px;color:#3379b7}", ""]);
// Exports
exports.locals = {
	"wrapper": "wrapper_2Lvod",
	"inputLabel": "inputLabel_hI5kD",
	"hidden": "hidden_3mb87",
	"required": "required_FP9h_"
};
module.exports = exports;
