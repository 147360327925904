import { Module, VuexModule, Mutation } from 'vuex-module-decorators'
import IConnectContractRenewal from '../Interfaces/IConnectContractRenewal'
import IConnectQuotation from '../Interfaces/connectQuotaton/IConnectQuotation'
import ConnectQuotation from '~/components/Product/ConnectQuotation'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'RenewalPartnerQuotationDefine',
})
export default class RenewalPartnerQuotationDefine extends VuexModule {
  connectContractRenewal: IConnectContractRenewal | null = null
  connectQuotation: IConnectQuotation | null = null
  edited: boolean = false
  isRenewalQuotation: boolean = false

  @Mutation
  setConnectContractRenewal(
    connectContractRenewal: IConnectContractRenewal | null
  ) {
    this.connectContractRenewal = connectContractRenewal
  }

  @Mutation
  setConnectQuotation(connectQuotation: IConnectQuotation) {
    this.connectQuotation = connectQuotation
  }

  @Mutation
  setEdited(edited: boolean) {
    this.edited = edited
  }

  @Mutation
  setIsRenewal(isRenewal: boolean) {
    this.isRenewalQuotation = isRenewal
  }

  get getConnectContractRenewal(): IConnectContractRenewal | null {
    return this.connectContractRenewal
  }

  get isRenewal(): boolean {
    return this.isRenewalQuotation
  }

  get canRequestConfirm(): boolean {
    if (this.connectQuotation === null) {
      return false
    }
    if (this.connectContractRenewal === null) {
      return true
    }

    if (
      this.connectQuotation.connectContractRenewalHashId ===
      this.connectContractRenewal.connectContractRenewalHashId
    ) {
      return true
    }

    if (this.edited) {
      return true
    }

    return false
  }
}
