import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectPartnerInquiry, {
  IConnectPartnerInquiryStatus,
  IConnectPartnerInquiryUploadPdfFile,
} from '~/Interfaces/ConnectPartnerInquiry/IConnectPartnerInquiry'

export default class ConnectPartnerInquiryService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async get(): Promise<IConnectPartnerInquiry[] | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get('api/company/partner_inquiry')
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async find(
    connectPartnerInquiryHashId: string
  ): Promise<IConnectPartnerInquiry | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get(
        'api/company/partner_inquiry/find/' + connectPartnerInquiryHashId
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async changeOrderStatus(
    connectPartnerInquiryHashId: string,
    orderStatus: IConnectPartnerInquiryStatus['orderStatus']
  ): Promise<IConnectPartnerInquiry | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/partner_inquiry/change_order_status/' +
          connectPartnerInquiryHashId,
        {
          orderStatus,
        }
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async changeResponseStatus(
    connectPartnerInquiryHashId: string,
    responseStatus: IConnectPartnerInquiryStatus['responseStatus']
  ): Promise<IConnectPartnerInquiry | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/partner_inquiry/change_response_status/' +
          connectPartnerInquiryHashId,
        {
          responseStatus,
        }
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async cancel(
    connectPartnerInquiryHashId: string
  ): Promise<IConnectPartnerInquiry | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/partner_inquiry/cancel/' + connectPartnerInquiryHashId
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async saveMemo(
    connectPartnerInquiryHashId: string,
    memo: string
  ): Promise<IConnectPartnerInquiry | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/partner_inquiry/save_memo/' + connectPartnerInquiryHashId,
        {
          memo,
        }
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async uploadPdf(
    connectPartnerInquiryHashId: string,
    uploadFile: IConnectPartnerInquiryUploadPdfFile
  ): Promise<IConnectPartnerInquiry | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const formData = new FormData()
      formData.append('name', uploadFile.name)
      formData.append('size', uploadFile.size.toString())
      formData.append('source_file', uploadFile.sourceFile)
      formData.append('thumbnail_file', uploadFile.thumbnailImg)
      const response = await this.axios.post(
        'api/company/partner_inquiry/upload_pdf/' + connectPartnerInquiryHashId,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async removeFile(
    connectPartnerInquiryHashId: string
  ): Promise<IConnectPartnerInquiry | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.post(
        'api/company/partner_inquiry/remove_file/' + connectPartnerInquiryHashId
      )
      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }

  async getPdf(connectPartnerInquiryHashId: string): Promise<Blob | undefined> {
    LockStore.lock(LockStore.types.company)
    try {
      const response = await this.axios.get(
        'api/company/partner_inquiry/pdf/' + connectPartnerInquiryHashId,
        {
          responseType: 'blob',
        }
      )
      return response.data
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.company)
    }
  }
}
