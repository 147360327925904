import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IConnectClientSurvey from '~/Interfaces/connectClientSurvey/IConnectClientSurvey'

export default class ConnectClientSurveyService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async answerPhase1(
    connectContractHashId: string,
    year: string,
    month: string,
    evaluation: string
  ): Promise<IConnectClientSurvey | undefined> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const data = {
        connectContractHashId,
        year,
        month,
        evaluation,
      }

      const response = await this.axios.post(
        'api/report/client_survey/answer1',
        data
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  async answerPhase2(
    connectContractHashId: string,
    year: string,
    month: string,
    satisfaction: number | null,
    achievement: number | null,
    quantityOfWork: number | null,
    qualityOfWork: number | null,
    report: number | null,
    communication: number | null,
    knowledge: number | null,
    cost: number | null,
    satisfiedPoint: string | null,
    dissatisfiedPoint: string | null
  ): Promise<boolean> {
    LockStore.lock(LockStore.types.quotation)
    try {
      const data = {
        connectContractHashId,
        year,
        month,
        satisfaction,
        achievement,
        quantityOfWork,
        qualityOfWork,
        report,
        communication,
        knowledge,
        cost,
        satisfiedPoint,
        dissatisfiedPoint,
      }

      await this.axios.post('api/report/client_survey/answer2', data)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.quotation)
    }
  }

  async findLast(
    connectProposalHashId: string
  ): Promise<IConnectClientSurvey | undefined> {
    try {
      const response = await this.axios.get(
        'api/company/client_survey/' + connectProposalHashId
      )
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    }
  }
}
