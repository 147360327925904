import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import { IConnectInvoice } from '~/Interfaces'
import ConnectInvoice from '~/components/Invoice/ConnectInvoice'

export default class ConnectInvoiceService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  async availability(): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.invoice)
    try {
      const response = await this.axios.get(`api/invoice/company/availability`)

      console.log(response)

      return JSON.parse(JSON.stringify(response.data)).isAvailable
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }

  async find(
    connectInvoiceHashId: string
  ): Promise<IConnectInvoice | undefined> {
    LockStore.lock(LockStore.types.invoice)
    try {
      const response = await this.axios.get(
        `api/invoice/company/find/${connectInvoiceHashId}`
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }

  async get(): Promise<IConnectInvoice[] | undefined> {
    try {
      const response = await this.axios.get('api/invoice/company/get')

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }

  async edit(
    connectInvoiceHashId: string,
    invoice: ConnectInvoice
  ): Promise<{ invoice: IConnectInvoice } | undefined> {
    LockStore.lock(LockStore.types.invoice)
    try {
      const response = await this.axios.post(
        `api/invoice/company/edit/${connectInvoiceHashId}`,
        {
          invoice: invoice.payload,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }

  async reject(
    connectInvoiceHashId: string,
    connectInvoiceRejectReason: number
  ): Promise<IConnectInvoice | undefined> {
    LockStore.lock(LockStore.types.invoice)
    try {
      const response = await this.axios.post(
        `api/invoice/company/reject/${connectInvoiceHashId}`,
        {
          connectInvoiceRejectReason,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }

  async reserve(
    connectInvoiceHashId: string
  ): Promise<IConnectInvoice | undefined> {
    LockStore.lock(LockStore.types.invoice)
    try {
      const response = await this.axios.post(
        `api/invoice/company/reserve/${connectInvoiceHashId}`
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }

  async reset(
    connectInvoiceHashId: string
  ): Promise<IConnectInvoice | undefined> {
    LockStore.lock(LockStore.types.invoice)
    try {
      const response = await this.axios.post(
        `api/invoice/company/reset/${connectInvoiceHashId}`
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }

  async copy(
    connectInvoiceHashId: string
  ): Promise<IConnectInvoice | undefined> {
    LockStore.lock(LockStore.types.invoice)
    try {
      const response = await this.axios.post(
        `api/invoice/company/copy/${connectInvoiceHashId}`
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }

  async csvDownload(
    year: number,
    month: number
  ): Promise<
    | {
        csv?: string
        filename?: string
      }
    | undefined
  > {
    LockStore.lock(LockStore.types.invoice)
    try {
      const response = await this.axios.post(
        'api/invoice/company/csv_download',
        {
          year,
          month,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.invoice)
    }
  }
}
